<template>
	<div class="orderList">
		<div class="search">
			<div class="search-l">
				<div class="l-item" style="width: 40%;">
					<div class="item-label">配送日期:</div>
					<div class="item-input" style="width: 80%;">
						<el-date-picker v-model="dateVal" type="daterange" range-separator="至" start-placeholder="开始日期"
							end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 100%;">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">团长信息:</div>
					<div class="item-input">
						<el-input v-model="searchForm.key" placeholder="请输入团长姓名/电话" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">是否结清:</div>
					<div class="item-input">
						<el-select v-model="searchForm.status" placeholder="请选择是否结清">
							<el-option v-for="item in statusOptions" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>

				<div class="l-item">
					<el-button type="warning"
						@click.stop="loading = true, page = 1, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">搜索</el-button>
					<el-button
						@click.stop="loading = true, page = 1, pageNum = 10, searchForm = {}, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="warning" @click.stop="settleFn()" :disabled="operationDisabled">批量结清</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>
				<span style="color:#F56C6C;padding-left:20px">配送单确认后自动生成配送对账单，多张配送单一起确认时只生成一张配送对账单，账单日就是配送日</span>

			</div>
			<el-table :data="tableData" style="width: 100%" border :header-cell-style="{
				background: '#f5f7fa',
				fontWeight: 'bold',
				color: '#303133'
			}" v-loading="loading" @selection-change="selectionChange">
				<el-table-column type="expand">
					<template slot-scope="props">
						<el-table :data="props.row.goods_list" :header-cell-style="{
							background: '#f5f7fa',
							fontWeight: 'bold',
							color: '#303133'
						}">

							<el-table-column prop="goods_name" label="商品名称" align="center"></el-table-column>
							<el-table-column label="商品主图" align="center">
								<template slot-scope="scope">
									<el-image style="width: 50px; height: 50px" :src="scope.row.goods_thumb"
										:preview-src-list="[scope.row.goods_pic]">
									</el-image>
								</template>
							</el-table-column>
							<el-table-column prop="goods_intro" label="商品卖点" align="center"></el-table-column>
							<el-table-column prop="attribute" label="自定义属性" align="center"></el-table-column>
							<el-table-column prop="price" label="单价" align="center"></el-table-column>
							<el-table-column prop="count" label="数量" align="center"></el-table-column>
							<el-table-column label="小计" align="center">
								<template slot-scope="scope">
									<span>{{ (Number(scope.row.price) * scope.row.count).toFixed(2) }}</span>
								</template>
							</el-table-column>
						</el-table>
					</template>
				</el-table-column>
				<el-table-column type="selection" width="55" :selectable="selectable"></el-table-column>
				<el-table-column label="对账单号" prop="ordernumber" align="center"></el-table-column>
				<el-table-column label="配送日期" prop="date" align="center"></el-table-column>
				<el-table-column label="配送仓库" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.warehouse_info.name }} /
							{{ scope.row.warehouse_info.phone }}</span>
					</template>
				</el-table-column>
				<el-table-column label="团长信息" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.member_info.username }}({{ scope.row.leader_info ? scope.row.leader_info.name : "-" }})
							/
							{{ scope.row.member_info.phone }}</span>
					</template>
				</el-table-column>
				<el-table-column label="金额" prop="amount" align="center"></el-table-column>
				<el-table-column label="收款方式" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.pay_type == 1">线上微信</span>
						<span v-if="scope.row.pay_type == 2">线上余额</span>
						<span v-if="scope.row.pay_type == 30">线下现金</span>
						<span v-if="scope.row.pay_type == 31">线下转账</span>
						<span v-if="scope.row.pay_type == 32">线下扫码</span>
						<span v-if="scope.row.pay_type == 39" style="color:#F56C6C;font-weight: 600;">线下其他</span>
					</template>
				</el-table-column>
				<el-table-column label="是否结清" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.status == 0">未结清</span>
						<span v-if="scope.row.status == 1">已结清</span>
					</template>
				</el-table-column>

				<el-table-column label="备注" prop="remark" align="center"></el-table-column>
				<el-table-column label="创建时间" prop="created_at" align="center"></el-table-column>
				<el-table-column label="操作" align="center" width="300">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" @click.stop="settleFn(scope.row)"
							v-if="scope.row.status == 0">结清</el-button>
						<el-button type="success" size="mini" @click.stop="shareFn(scope.row)">分享</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="page" @size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()" :current-page="page" :page-sizes="pageSizes"
				layout="total, sizes, prev, pager, next, jumper" :page-size="pageNum" :total="total">
			</el-pagination>
		</div>

		<el-dialog title="结清确认" :visible.sync="dialogVisible" :close-on-click-modal="false" v-el-drag-dialog
			width="30%">
			<el-form ref="form" label-width="120px">
				<el-form-item label="收款方式">
					<el-select v-model="dialogForm.pay_type" placeholder="请选择收款方式" style="width: 100%;" filterable>
						<el-option v-for="item in payList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="备注">
					<el-input v-model="dialogForm.remark">
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="dialogVisible = false">取 消</el-button>
				<el-button type="warning" @click.stop="operationFn()">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="请复制下方链接到微信打开" :visible.sync="dialogShareVisible" :close-on-click-modal="false"
			v-el-drag-dialog width="30%">
			<el-form ref="form" label-width="100px">
				<el-form-item label="微信链接：">
					<el-input type="textarea" rows="5" placeholder="" v-model="shortLink" readonly=""></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="dialogShareVisible = false">关 闭</el-button>
				<el-button type="warning" @click.stop="copyFn()">复制并关闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dateVal: [],
				searchForm: {
					begin: '',
					end: '',
				},
				item: {},
				dialogForm: {
					income_type: 0,
				},
				// 状态
				statusOptions: [{
						id: 0,
						name: '未结清'
					},
					{
						id: 1,
						name: '已结清'
					}
				],
				dialogVisible: false,
				dialogShareVisible: false,
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				pageSizes: [10, 50, 200, 1000],
				selectionList: [],
				operationDisabled: true,
				shortLink: "",
				incomeList: [],
				// 支付方式
				payList: [{
						id: 30,
						name: '线下现金'
					},
					{
						id: 31,
						name: '线下转账'
					},
					{
						id: 32,
						name: '线下扫码'
					},
					{
						id: 39,
						name: '线下其他'
					}
				],
				supplierInfo: {}
			}
		},
		mounted() {
			// 时间
			this.dateVal = [this.getCurrentDateFirst(), this.getCurrentDate()]
			this.searchForm.begin = this.dateVal[0]
			this.searchForm.end = this.dateVal[1]

			//获取供应商最新设置
			this.getSetting()
			// 列表
			this.getList()

		},
		methods: {
			selectable(row, index) {
				if (row.status == 0) {
					return true;
				} else {
					return false;
				}
			},
			// 获取
			getSetting() {
				this.$http.post(`/erp/v1/home/get_info`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.supplier_info = data.info
					} else {
						this.$message.error(msg);
					}
				});
			},
			getList() {
				this.$http.post(`/erp/v1/statement/lst`, {
					page: this.page,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			getIncomeList() {
				this.loading = true
				this.$http.post(`/erp/v1/financial/income/type/all`, {
					page: this.page,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false

					if (code == 200) {
						this.incomeList = data
						if (this.incomeList.length > 0) {
							this.dialogForm.income_type = this.incomeList[0].id
						}
						this.dialogForm.pay_type = this.payList[0].id
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			// 获取当前年月第一天
			getCurrentDateFirst() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');

				return `${year}-${month}-01`;
			},
			// 结清
			settleFn(item) {
				if (item) {
					this.dialogForm.id = item.id
				} else {
					if (this.selectionList.length <= 0) {
						this.$message({
							type: 'error',
							message: '请选择数据'
						});
						return
					}
					let idArr = []
					this.selectionList.forEach(item => {
						idArr.push(item.id)
					});
					this.dialogForm.id = idArr.join(',')
				}
				this.dialogVisible = true
				//this.getIncomeList()
			},
			operationFn() {
				this.$http.post(`/erp/v1/statement/settle`, {
					...this.dialogForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.dialogVisible = false
						this.getList()
						this.$message.success(msg);
					} else {
						this.$message.error(msg);
					}
				});
			},
			shareFn(item) {
				this.$http.post(`/erp/v1/statement/share`, {
					id: item.id
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.dialogShareVisible = true
						this.shortLink = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 选择表格数据
			selectionChange(e) {
				this.selectionList = e
				if (this.selectionList.length > 0) {
					this.operationDisabled = false
				} else {
					this.operationDisabled = true
				}
			},
			copyFn() {
				var input = document.createElement('input');
				input.value = this.shortLink; // 将文本赋值给临时输入框
				document.body.appendChild(input); // 将输入框添加到页面中
				input.select(); // 选中输入框的内容
				document.execCommand('copy'); // 执行复制操作
				document.body.removeChild(input); // 移除临时输入框
				this.$message.success('成功复制到剪贴板'); // 提示复制成功
				this.dialogShareVisible = false
			}
		},
	}
</script>

<style lang="scss" scoped>
	.export-template {
		#template-title {
			.title-t {
				text-align: center;
				margin-bottom: 20px;
				font-weight: bold;
				font-size: 22px;

			}

			.title-box {
				display: flex;
				flex-wrap: wrap;

				.box-item {
					width: 50%;
					display: flex;
					justify-content: center;
					margin-bottom: 20px;

					.item-i {
						width: 60%;

						span {
							padding-right: 20px;
						}
					}
				}
			}
		}
	}

	.page {
		margin-top: 20px;
		text-align: right;
	}
</style>